export const getFromSessionStorage = (key) => {
  if (typeof window !== 'undefined') {
    const result = sessionStorage.getItem(key);
    if (result) {
      return JSON.parse(result);
    }

    return null;
  }
  return null;
};

export const setToSessionStorage = (key, state) => {
  if (typeof window !== 'undefined') {
    sessionStorage.setItem(key, JSON.stringify(state));
  }
};
