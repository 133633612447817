import React, { createContext, useContext, useReducer } from 'react';
import PropTypes from 'prop-types';
import stateReducer from './stateReducer';
import initialState from './initialState';

export const StateContext = createContext(null);

export const StateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(stateReducer, initialState());

  return (
    <StateContext.Provider value={[state, dispatch]}>
      {children}
    </StateContext.Provider>
  );
};

StateProvider.propTypes = {
  children: PropTypes.node
};

export const useSessionState = () => useContext(StateContext);
